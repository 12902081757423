import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";

import omit from "lodash/omit";

let blacklistTransform = createTransform((inboundState, key) => {
  if (key === "apiData") {
    return omit(inboundState, "endpointConfig");
  } else {
    return inboundState;
  }
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["products", "apiData"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewareEnhancer = applyMiddleware(thunkMiddleware);

const composedEnhancers = composeWithDevTools(middlewareEnhancer);

const store = createStore(persistedReducer, composedEnhancers);

export const persistor = persistStore(store);

export default store;
