// import { update } from "redux-updaters";
import { performApiRequest } from "react-api-data";

const ENDPOINT = "https://maya-api.onrender.com";

//"https://maya-test.herokuapp.com"
export const endpointConfig = {
  getAddress: {
    url: "https://json.api-postcode.nl/:postcode/:number",
    method: "GET",
    autoTrigger: false,
    setHeaders: (defaultHeaders) => ({
      ...defaultHeaders,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      token: `9c47cf8c-1f5e-439c-a828-f68b33bcba5a`,
    }),
  },
  mollieOrder: {
    url: `${ENDPOINT}/mollie-order`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      window.location.replace(resultData.checkoutUrl);
    },
  },
  mollieCustomer: {
    url: `${ENDPOINT}/mollie-customer`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      //   dispatch(update("payment.customerId", resultData.id));
      dispatch(
        performApiRequest(
          "mollieRecurringPayment",
          {},
          {
            customerId: resultData.id,
            amount: resultData.metadata,
            locale: resultData.locale,
          }
        )
      );
    },
  },
  mollieMandate: {
    url: `${ENDPOINT}/mollie-mandate`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(
        performApiRequest(
          "mollieSubscription",
          {},
          {
            customerId: resultData.customerId,
            amount: resultData.amount,
            method: resultData.method,
          }
        )
      );
    },
  },
  mollieSubscription: {
    url: `${ENDPOINT}/mollie-subscription`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      //   dispatch(update("payment.subscriptionId", resultData.id));
    },
  },
  molliePayment: {
    url: `${ENDPOINT}/mollie-payment`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      if (resultData.sequenceType === "first") {
        window.location.replace(resultData.checkoutUrl);
        // dispatch(update("payment.paymentAmount", resultData.amount.value));
        // dispatch(update("payment.paymentId", resultData.id));
      } else if (resultData.sequenceType === "oneoff") {
        window.location.replace(resultData.checkoutUrl);
        // dispatch(update("payment.paymentId", resultData.id));
      }
    },
  },
  mollieSinglePayment: {
    url: `${ENDPOINT}/mollie-single-payment`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      console.log(resultData);
      if (resultData.sequenceType === "first") {
        window.location.replace(resultData.checkoutUrl);
        // dispatch(update("payment.paymentAmount", resultData.amount.value));
        // dispatch(update("payment.paymentId", resultData.id));
      } else if (resultData.sequenceType === "oneoff") {
        window.location.replace(resultData.checkoutUrl);
        // dispatch(update("payment.paymentId", resultData.id));
      }
    },
  },
  mollieRecurringPayment: {
    url: `${ENDPOINT}/mollie-recurring-payment`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      if (resultData.sequenceType === "first") {
        window.location.replace(resultData.checkoutUrl);
        // dispatch(update("payment.paymentAmount", resultData.amount.value));
        // dispatch(update("payment.paymentId", resultData.id));
      } else if (resultData.sequenceType === "oneoff") {
        window.location.replace(resultData.checkoutUrl);
        // dispatch(update("payment.paymentId", resultData.id));
      }
    },
  },
  getOrder: {
    url: `${ENDPOINT}/mollie-order/:id`,
    method: "GET",
  },
  getPayment: {
    url: `${ENDPOINT}/mollie-payment/:id`,
    method: "GET",
  },
  getCustomer: {
    url: `${ENDPOINT}/mollie-customer/:id`,
    method: "GET",
    afterSuccess: ({ resultData, dispatch, getState }) => {
      const state = getState();
      dispatch(
        performApiRequest(
          "subscribeMailchimp",
          {},
          {
            email: resultData.email,
          }
        )
      );
    },
  },
  mayahomeCustomer: {
    url: `${ENDPOINT}/mayahome/mollie-customer`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(
        performApiRequest(
          "mayahomeRecurringPayment",
          {},
          {
            customerId: resultData.id,
            amount: resultData.metadata,
            locale: resultData.locale,
          }
        )
      );
    },
  },
  mayahomeMandate: {
    url: `${ENDPOINT}/mayahome/mollie-mandate`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch, getState }) => {
      const state = getState();
      dispatch(
        performApiRequest(
          "mayahomeSubscription",
          {},
          {
            customerId: resultData.customerId,
            amount: state.payment.paymentAmount,
            method: resultData.method,
          }
        )
      );
    },
  },
  mayahomeSubscription: {
    url: `${ENDPOINT}/mayahome/mollie-subscription`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      dispatch(update("payment.subscriptionId", resultData.id));
    },
  },
  mayahomeRecurringPayment: {
    url: `${ENDPOINT}/mayahome/mollie-recurring-payment`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      window.location.replace(resultData.checkoutUrl);
    },
  },
  mayahomeDonation: {
    url: `${ENDPOINT}/mayahome/mollie-payment-once`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      window.location.replace(resultData.checkoutUrl);
    },
  },
  mayahomeGetPayment: {
    url: `${ENDPOINT}/mayahome/mollie-payment/:id`,
    method: "GET",
  },
  getTours: {
    url: `${ENDPOINT}/rides`,
    method: "GET",
  },
  postTour: {
    url: `${ENDPOINT}/rides`,
    method: "POST",
  },
  getClasses: {
    url: `${ENDPOINT}/classes`,
    method: "GET",
  },
  sponsorClass: {
    url: `${ENDPOINT}/mollie-hal`,
    method: "POST",
    afterSuccess: ({ resultData, dispatch }) => {
      window.location.replace(resultData.checkoutUrl);
    },
  },
};
