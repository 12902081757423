import React from "react";
import { Provider } from "react-redux";
import { configureApiData } from "react-api-data";
import store from "./src/store";
import { endpointConfig } from "./src/endpointconfig";

store.dispatch(configureApiData({}, endpointConfig));
export default ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};
