// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-jsx": () => import("./../../../src/templates/about.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-blog-overview-jsx": () => import("./../../../src/templates/BlogOverview.jsx" /* webpackChunkName: "component---src-templates-blog-overview-jsx" */),
  "component---src-templates-blog-page-jsx": () => import("./../../../src/templates/BlogPage.jsx" /* webpackChunkName: "component---src-templates-blog-page-jsx" */),
  "component---src-templates-donation-page-jsx": () => import("./../../../src/templates/DonationPage.jsx" /* webpackChunkName: "component---src-templates-donation-page-jsx" */),
  "component---src-templates-foundation-thanks-jsx": () => import("./../../../src/templates/FoundationThanks.jsx" /* webpackChunkName: "component---src-templates-foundation-thanks-jsx" */),
  "component---src-templates-home-thanks-jsx": () => import("./../../../src/templates/HomeThanks.jsx" /* webpackChunkName: "component---src-templates-home-thanks-jsx" */),
  "component---src-templates-kathman-tour-page-jsx": () => import("./../../../src/templates/kathmanTourPage.jsx" /* webpackChunkName: "component---src-templates-kathman-tour-page-jsx" */),
  "component---src-templates-landing-jsx": () => import("./../../../src/templates/landing.jsx" /* webpackChunkName: "component---src-templates-landing-jsx" */),
  "component---src-templates-maya-home-jsx": () => import("./../../../src/templates/MayaHome.jsx" /* webpackChunkName: "component---src-templates-maya-home-jsx" */),
  "component---src-templates-order-thanks-jsx": () => import("./../../../src/templates/OrderThanks.jsx" /* webpackChunkName: "component---src-templates-order-thanks-jsx" */),
  "component---src-templates-project-page-jsx": () => import("./../../../src/templates/ProjectPage.jsx" /* webpackChunkName: "component---src-templates-project-page-jsx" */),
  "component---src-templates-projects-overview-jsx": () => import("./../../../src/templates/ProjectsOverview.jsx" /* webpackChunkName: "component---src-templates-projects-overview-jsx" */),
  "component---src-templates-vision-page-jsx": () => import("./../../../src/templates/VisionPage.jsx" /* webpackChunkName: "component---src-templates-vision-page-jsx" */)
}

