import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHECKOUT_REQUEST,
  CHECKOUT_FAILURE,
} from "../constants/Types";

const initialState = {
  addedIds: [],
  quantityById: {},
};

const quantityById = (state = initialState.quantityById, action, productId) => {
  switch (action.type) {
    case ADD_TO_CART:
      // const { productId } = action
      return {
        ...state,
        [action.productId]: (state[action.productId] || 0) + action.quantity,
      };
    case REMOVE_FROM_CART:
      if (state[action.productId] > 0)
        return {
          ...state,
          [action.productId]: (state[action.productId] || 0) - action.quantity,
        };

    default:
      return state;
  }
};

const addedIds = (state = initialState.addedIds, action, quantityById) => {
  switch (action.type) {
    case ADD_TO_CART:
      if (state.indexOf(action.productId) !== -1) {
        return state;
      }
      return [...state, action.productId];
    case REMOVE_FROM_CART:
      if (state.quantityById < 1) {
        return [
          ...state.slice(
            0,
            state.indexOf(action.productId),
            ...state.slice(state.indexOf(action.productId) + 1)
          ),
        ];
      }
    default:
      return state;
  }
};

export const getQuantity = (state, productId) =>
  state.quantityById[productId] || 0;

export const getAddedIds = (state) => state.addedIds;

const cart = (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_REQUEST:
      return initialState;
    case CHECKOUT_FAILURE:
      return action.cart;
    default:
      return {
        addedIds: addedIds(state.addedIds, action),
        quantityById: quantityById(state.quantityById, action),
      };
  }
};

export default cart;
